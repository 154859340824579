import {BrowserRouter, Routes, Route} from "react-router-dom";
import Base from "./components/Base";
import CourseItem from "./components/CourseItem";
function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Base/>}>
            </Route>

            <Route path='/course/:id' element={<CourseItem/>}>
            </Route>

        </Routes>



    </BrowserRouter>
  );
}

export default App;
