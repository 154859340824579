import React, {useState} from "react";
import NavBar from "./NavBar";
import HomePageBanner from "./HomePageBanner";
import Courses from "./Courses";
import MeetTheTeam from "./MeetTheTeam";
import Footer from "./Footer";
import MissionStatement from "./MissionStatement";
import CourseItem from "./CourseItem";
import ContactUs from "./ContactUs";
import OurLocations from "./OurLocations";


const Base = () => {
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(null)

  return (
      <div className="">
          <div className="w-full">
              <NavBar></NavBar>
              <HomePageBanner></HomePageBanner>
              <MissionStatement/>
              <Courses
                  setShowModal={setShowModal}
                  setModalData={setModalData}

              ></Courses>
              <MeetTheTeam></MeetTheTeam>

              <OurLocations/>
              <ContactUs/>
              <Footer></Footer>
          </div>
          {showModal && (
              <>
                  <div className="modal-backdrop" onClick={() => setShowModal(false)} />
                  <div className="modal">
                      <CourseItem setShowModal={setShowModal} data={modalData} />
                  </div>
              </>
          )}
      </div>
      )
}

export default Base