import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import CourseItem from "./CourseItem";

const Courses = ({setShowModal, setModalData}) => {
    const navigate = useNavigate()

    const coursesOffers = [
        {
            'title': "Python for Data Processing and Analysis",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/c1.webp",
            'slug': 'python',
            't1': '30+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c1.webp',
            'description': 'This course introduces the essentials of data science and analytics, focusing on Python for data acquisition, examination, and visualization. It\'s designed for individuals keen on programming within data science. Beginning with Python basics, the curriculum advances to complex data analysis using Python\'s libraries. The teaching approach is hands-on, requiring active engagement rather than passive memorization, ideal for practical applications in diverse sectors.',
            'outcome': 'Participants will learn to effectively process, analyze, and visualize data using Python, applicable across various business environments. Mastery of Python\'s libraries will equip learners with foundational and advanced data handling skills, preparing them for significant roles in data-driven decision-making and enhancing their career prospects in technology and business analytics.'
        },
        {
            'title': "Data Story Telling",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/Data-StoryTelling.webp",
            'slug': 'data',
            't1': '30+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c2.webp',
            'description': 'This course is tailored for professionals looking to enhance their data analysis and presentation skills through storytelling and visualizations. It combines theoretical concepts with practical tools, including Microsoft Power Pivot, Data Analysis Expressions, and Power BI. Participants will learn to communicate effectively using data, producing impactful visualizations and dashboards that convey stories and insights in an engaging and comprehensible manner.',
            'outcome': 'Participants will master the art of data storytelling, learning to select the most effective approaches to present and communicate data insights. They will gain proficiency in using advanced visualization tools like Power BI to create dashboards and scorecards, significantly enhancing their ability to influence decisions and strategies in their respective organizations.'
        },
        {
            'title': "Blockchain in Business Applications",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/BlockChain.webp",
            'slug': 'blockchain',
            't1': '30+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c3.webp',
            'description': 'This course provides a deep dive into blockchain technology combined with business insights. Starting with blockchain\'s impact on business inefficiencies, it covers foundational aspects like cryptology and distributed computing. The curriculum includes detailed studies of major platforms like Bitcoin and Ethereum, emphasizing smart contracts and dApps, and explores blockchain\'s potential across industries like finance and healthcare.',
            'outcome': 'Students will understand blockchain\'s business and technical aspects, recognize inefficiencies in current economic systems, and grasp technologies like cryptology and peer-to-peer networking. The course fosters skills in analyzing and implementing blockchain applications, preparing participants to design and prototype strategic dApps in a business context.'
        },
        {
            'title': "AI in Business Applications",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/AI-Business.webp",
            'slug': 'ai',
            't1': '30+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c4.webp',
            'description': 'Explore the evolution of Artificial Intelligence (AI) and its strategic business applications. This course covers AI\'s history, significant developments, and current roles in industries, emphasizing how businesses can leverage AI for competitive advantage. Ethical considerations and AI\'s impact on business models and strategies are also discussed, aiming at a high-level understanding suitable for future leaders.',
            'outcome': 'Participants will trace AI\'s development and learn to forecast its future impacts. The course encourages critical analysis of AI\'s influence on business operations and strategic planning. Ethical discussions will enable responsible management of AI applications, preparing learners to strategically implement AI across sectors like healthcare and finance.'
        },
        {
            'title': "Big Data and Cloud Computing",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/BigData.webp",
            'slug': 'bidata',
            't1': '30+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c5.webp',
            'description': 'Delve into Big Data and IoT, highlighting their role in revolutionizing industries through data generation. This course tackles the challenges of managing vast data volumes with modern technologies and tools like Hadoop and Spark. It also covers strategic implications for business innovation and competitive advantage through hands-on learning experiences.\n',
            'outcome': 'By the end, students will master Big Data technologies and cloud computing, capable of addressing organizational data challenges. Skills in data analysis, predictive modeling, and application across business scenarios will be developed, enabling effective solutions that leverage Big Data and IoT for enhanced operational efficiency and wealth creation.'
        },
        {
            'title': "Statistical foundations for Data Science",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/MathsAI.webp",
            'slug': 'math',
            't1': '40+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c6.webp',
            'description': 'This course provides practical knowledge of data gathering and summarizing using descriptive statistics. It explores the relationships between variables, expected values, and uses probability distributions for data modeling. The course is designed to build competence in creating and validating hypotheses through hands-on activities and examples.',
            'outcome': 'Participants will master statistical methods necessary for data science, including data gathering, summarization, and hypothesis testing. This foundation will enable them to effectively analyze and interpret data, essential for careers in data science and analytics.'
        },
        {
            'title': "Mathematics for Machine Learning using Python",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/MathsAI.webp",
            'slug': 'math',
            't1': '40+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c7.webp',
            'description': 'Dive into the essential mathematics for machine learning, covering topics such as linear algebra, calculus, and probability and statistics. This course emphasizes practical applications in machine learning, teaching participants how to use these mathematical tools to solve real-world problems in technology and business analytics.',
            'outcome': 'Participants will acquire a solid understanding of the mathematical concepts required for machine learning. They will be equipped to apply linear algebra, calculus, and statistics to develop and fine-tune machine learning models effectively.'
        },
        {
            'title': "Machine Learning with Python",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/MathsAI.webp",
            'slug': 'math',
            't1': '56+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c8.webp',
            'description': 'This comprehensive course covers a broad range of machine learning algorithms for classification and clustering data. Participants will learn to use Python to implement these machine learning methods, tailored for real-world business applications. The course will provide a detailed understanding of various analytics methods and practical insights into deploying machine learning solutions effectively.',
            'outcome': 'Participants will gain proficiency in applying machine learning techniques using Python. They will be able to classify and cluster data, understand key analytics methods, and leverage these skills to solve business problems, preparing them for advanced roles in data analysis and machine learning.'
        },
        {
            'title': "Foundations of Machine Learning",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/MathsAI.webp",
            'slug': 'math',
            't1': '40+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c9.webp',
            'description': 'This course introduces foundational machine learning concepts and techniques, emphasizing a no-code approach using the RapidMiner environment. Participants will explore various machine learning algorithms for classification and clustering and understand how to implement these in business contexts without needing extensive coding expertise.',
            'outcome': 'By the end of the course, participants will have a clear understanding of core machine learning algorithms and the ability to apply them using RapidMiner. This skill set will enable them to contribute to business analytics and decision-making processes effectively, enhancing their career prospects in analytics and data science fields.'
        },
        {
            'title': "Computer Vision with Python",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/MathsAI.webp",
            'slug': 'math',
            't1': '40+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c10.webp',
            'description': 'This course provides a comprehensive introduction to computer vision using Python. Participants will learn to handle basic image processing tasks, and advanced techniques for object detection and segmentation. The course aims to build both conceptual understanding and hands-on skills in computer vision applications.',
            'outcome': 'By the end of the course, students will be proficient in managing computer vision projects and implementing image processing tasks using Python. They will be prepared for roles in software engineering and computer vision, enhancing their capabilities in image analysis and machine learning.'
        },
        {
            'title': "Natural Language processing with Python",
            'image': "https://storage.googleapis.com/betsite-imagedata/elearning/MathsAI.webp",
            'slug': 'math',
            't1': '40+ Hrs of Live Sessions',
            't2': '1 Group Project',
            'logo': 'https://storage.googleapis.com/betsite-imagedata/elearning/c11.webp',
            'description': 'This course explores practical NLP techniques using machine learning and deep learning methods such as word embeddings, CNNs, LSTMs, and encoder-decoder models. Participants will learn to manage common tasks with text data, extracting topics, and building classifiers, using libraries like NLTK and other deep learning tools.',
            'outcome': 'Participants will develop comprehensive skills in handling various NLP tasks, enabling them to apply these techniques in business and research. They will gain the capability to implement advanced NLP solutions, enhancing their qualifications for roles in data science and machine learning.'
        }


    ]

    const modalClick = (course) => {
        setShowModal(true)
        setModalData(course)
    }

    return <div className="flex flex-col justify-center items-center py-8 px-4 bg-gradient-to-b from-[#FFFFFF]  to-[#FFF6F3]" id={"coursesSection"}>
        <div className="font-[900] text-center text-[#E3E3E3] text-[52px] py-2 ">OUR COURSES</div>
        <div className="grid grid-cols-1 lg:grid-cols-3 justify-center">
            {coursesOffers.map((course) => {
                return <div className=" mx-2  my-2 w-[300px] hover:border shadow-2xl flex flex-col rounded-xl bg-white" onClick={()=>{modalClick(course)}}>

                    <div className="flex items-center bg-[#D5D7DE] text-[12px] font-bold rounded-xl">
                        <div className="w-1/5 m-1 ml-2">
                            <img className="rounded-full w-3/5" src={course.logo}/>
                        </div>
                        <div className="flex-1">{course.title}</div>

                    </div>
                    <div className="flex justify-between mx-2 text-[10px] my-2 font-mono py-2 px-2 ">
                        <div>{course.t1}</div>
                        <div>{course.t2}</div>
                    </div>

                </div>
            })}
        </div>

    </div>
}

export default Courses