import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import CourseItem from "./CourseItem";

const OurLocations = ({setShowModal, setModalData}) => {
    const navigate = useNavigate()

    const locations = [
        {
            'name': 'Kolkata',
            'country': 'India',
            'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/kolkata.webp'
        },
        {
            'name': 'Delhi',
            'country': 'India',
            'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/delhi.webp'
        },
        {
            'name': 'Mumbai',
            'country': 'India',
            'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/mumbai.webp'
        },
        {
            'name': 'Bangalore',
            'country': 'India',
            'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/bangalore.webp'
        },
        {
            'name': 'Singapore',
            'country': 'India',
            'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/singapore.webp'
        },
        {
            'name': 'Kuala Lumpur',
            'country': 'Malaysia',
            'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/kuala.webp'
        },
        {
            'name': 'Manila',
            'country': 'Phillippines',
            'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/manila.webp'
        }
    ]

    return <div className="flex flex-col justify-center items-center py-8 px-4 bg-gradient-to-b from-[#FFFFFF]  to-[#FFF6F3]" id={"coursesSection"}>
        <div className="font-[900] text-center text-[#E3E3E3] text-[52px] py-2 ">OUR LOCATIONS</div>
        <div className="grid grid-cols-1 lg:grid-cols-3 justify-center">
            {locations.map((course) => {
                return <div className=" mx-2  my-2 w-[300px] hover:border shadow-2xl flex flex-col rounded-xl bg-white" >

                    <div className="flex items-center bg-[#FFFFFF] text-[12px] font-bold rounded-xl">
                        <div className="w-1/5 m-1 ml-2">
                            <img className="rounded-full w-3/5" src={course.image}/>
                        </div>
                        <div className="flex-1">{course.name}, {course.country}</div>

                    </div>


                </div>
            })}
        </div>

    </div>
}

export default OurLocations