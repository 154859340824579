import React from "react";

const ContactUs = () => {
    return <div className="flex flex-col   py-2 px-4 bg-gradient-to-b from-[#FFF6F3] via-[#FFFFFF] to-[#FFFFFF] items-center w-full" id={'contact'}>
        <div className="flex flex-col ">
            <div className="font-[900] text-center text-[#f8dfd7] text-[52px] py-2 ">CONTACT US</div>
            <div className="bg-white rounded-lg m-6 p-4 shadow-2xl flex flex-col justify-center">
                <div className="my-2">
                    <input className="flex-1 border-2 border-[#f8dfd7] rounded px-2 w-full h-8" placeholder={'Name'}/>
                </div>
                <div className="my-2">
                    <input className="border-2 border-[#f8dfd7] rounded px-2  w-full h-8" placeholder={'Email'}/>
                </div>
                <div className="my-2">
                    <input className="border-2 border-[#f8dfd7] rounded px-2  w-full h-8" placeholder={'Phone'}/>
                </div>
                <div className="my-2">
                    <textarea  className="border-2 border-[#f8dfd7] rounded p-2 h-32 text-wrap w-full" placeholder={'Message'}/>
                </div>

                <button className="bg-orange-400 rounded p-2 mx-8 items-center text-white font-bold text-xl">Submit</button>
            </div>
        </div>


    </div>

}

export default ContactUs