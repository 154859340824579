import React , {useState} from "react";
import { useSwipeable } from 'react-swipeable';
import {RiTeamLine} from "react-icons/ri";

const MeetTheTeam = () => {
  const homePageTeam = [
      {
          'name': "Malaya Khandelwal",
          'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/malaya3.png',
          'title': 'Co-Founder & Trainer',
          'tag': '\n' +
              'Malaya Khandelwal, a B. Tech graduate from IIT Madras and holder of an M.S. from Carnegie Mellon University, specializes in Mathematics for AI and modeling. He leads a team that develops advanced AI solutions for law enforcement agencies, enhancing their operational efficiency and decision-making capabilities through innovative applications of artificial intelligence in public safety.',
          'skills': ['Maths', 'AI'],
          'education': 'MS, Carnegie Mellon, USA'
      },
      {
          'name': "Rana Bhattacharjee",
          'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/rana-300-bg.png',
          'title': 'Co-Founder & Trainer',
          'tag': 'Rana Bhattacharjee, with 12 years in IT consulting and over 20 years in financial services, leads Aestas Consulting in Southeast Asia. He provides Big Data, AI, and Machine Learning solutions to various industries. An experienced trainer, he has educated many in multiple tech disciplines. He holds degrees from Delhi University, IIM Calcutta, and the National University of Singapore.',
          'skills': ['AI', 'Python', 'Data'],
          'education': 'M. Tech, National University of Singapore'
      },
      {
          'name': "Pradyum Mohta",
          'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/prdy-300-bg.png',
          'title': 'Co-Founder & Trainer',
          'tag': 'Pradyum Mohta holds an MS in Information Systems from the University of Maryland, following his BTech in Kolkata, India. He is an expert in Python, big data, cloud computing, machine learning, AI, and blockchain. Pradyum has applied his extensive knowledge to various international projects, showcasing his ability to innovate and implement advanced technological solutions across a broad range of industries.',
          'skills': ['AI', 'BlockChain', 'Python'],
          'education': 'MS, University of Maryland, USA'
      },
      {
          'name': "Sumit Chakraborty",
          'image': 'https://storage.googleapis.com/betsite-imagedata/elearning/sumit3.png',
          'title': 'Advisor',
          'tag': 'Sumit is a senior Technology professional with over 30 years of experience in various industries in India and overseas, including defence, banking, financial services and education technology. He has managed large programs and delivery units in his career. He holds a Bachelor in Technology from Indian Institute of Technology Kharagpur and a Masters in Technology from Indian Institute of Technology Madras.',
          'skills': ['AI', 'BlockChain', 'Python'],
          'education': 'M.Tech, IIT Kharagpur, INDIA'
      }
  ]
    const [index, setIndex] = useState(0);
    const handlers = useSwipeable({
        onSwipedLeft: () => setIndex((index + 1) % homePageTeam.length),
        onSwipedRight: () => setIndex((index - 1 + homePageTeam.length) % homePageTeam.length),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    React.useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % homePageTeam.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);


    return <div className="bg-gradient-to-b from-[#FFF6F3]  to-[#FFFFFF] py-20" id={'team'}>
        <div className="font-[900] text-center text-[#f8dfd7] text-[56px] py-2 ">OUR TEAM</div>
        <div className="flex flex-col   ">
            <div className="flex flex-col">
                <div className="pb-6 flex items-center justify-center">
                    <div className="pl-8">
                        <RiTeamLine/>
                    </div>
                    <div className="pl-4 pr-2 text-left font-bold">Team that brings out the best in YOU.</div>
                </div>
                {/*<div className="text-right text-xs mx-4 my-2">See whole Team</div>*/}
            </div>
            <div className="flex flex-row items-center justify-center  lg:hidden" {...handlers}>
                {homePageTeam.map((team, idx) => {
                    return idx === index && <div className="flex flex-col w-[256px] h-[400px] px-4 py-6 shadow-2xl rounded-xl bg-white">
                        <div className=" flex justify-center bg-[linear-gradient(180deg,#5780e9 0%,#d5dff9 100%)] ">
                            <img className="rounded-full h-[100px] w-[100px]  bg-gradient-to-b from-[#5780e9] to-[#d5dff9]" src={team.image}/>
                        </div>
                        <div  className=" flex flex-col  pt-2 text-base items-center">

                            <div className="font-base font-semibold">{team.name}</div>
                            <div className="text-xs font-extralight">{team.title}</div>
                            <div className="py-2 text-xs text-center">"{team.tag}"</div>
                            {/*<div className="py-4">*/}
                            {/*    <div className="text-center text-xs font-semibold text-gray-500">Skills</div>*/}
                            {/*    {team.skills.map((skill) => {*/}
                            {/*        return <div className="text-center text-xs">{skill}</div>*/}
                            {/*    })}*/}
                            {/*</div>*/}
                            {/*<div className="py-4">*/}
                            {/*    <div className="text-center text-xs font-semibold text-gray-500">Background</div>*/}
                            {/*    <div className="text-center text-xs">{team.education}</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                })}
            </div>
            <div className="lg:hidden flex py-4 justify-center">
                <div className={`w-2 rounded-full h-2 mx-1 ${index === 0 ? 'bg-gray-600' : 'bg-gray-400'}`}></div>
                <div className={`w-2 rounded-full h-2 mx-1 ${index === 1 ? 'bg-gray-600' : 'bg-gray-400'}`}></div>
                <div className={`w-2 rounded-full h-2 mx-1 ${index === 2 ? 'bg-gray-600' : 'bg-gray-400'}`}></div>
                <div className={`w-2 rounded-full h-2 mx-1 ${index === 3 ? 'bg-gray-600' : 'bg-gray-400'}`}></div>
            </div>
            <div className="lg:flex flex-row items-center justify-center  hidden">
                {homePageTeam.map((team, idx) => {
                    return  <div className="flex flex-col w-[256px] h-[400px] px-4 py-6 shadow-2xl rounded-xl mr-4 bg-white">
                        <div className=" flex justify-center  ">
                            <img className="rounded-full h-[100px] w-[100px]  bg-gradient-to-b from-[#5780e9] to-[#d5dff9] " src={team.image}/>
                        </div>
                        <div  className=" flex flex-col  pt-2 text-base items-center">

                            <div className="font-base font-semibold">{team.name}</div>
                            <div className="text-xs font-extralight">{team.title}</div>
                            <div className="py-2 text-xs text-center">"{team.tag}"</div>
                            {/*<div className="py-4">*/}
                            {/*    <div className="text-center text-xs font-semibold text-gray-500">Skills</div>*/}
                            {/*    {team.skills.map((skill) => {*/}
                            {/*        return <div className="text-center text-xs">{skill}</div>*/}
                            {/*    })}*/}
                            {/*</div>*/}
                            {/*<div className="py-4">*/}
                            {/*    <div className="text-center text-xs font-semibold text-gray-500">Background</div>*/}
                            {/*    <div className="text-center text-xs">{team.education}</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                })}
            </div>
        </div>

    </div>
}

export default MeetTheTeam;