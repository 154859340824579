import React from "react";

const MissionStatement = () => {

    return <div className="flex flex-col justify-center items-center py-8 px-4 bg-gradient-to-b from-[#FFF6F3] to-[#FFFFFF]" id={'why-us'}>
        <div className="font-[900] text-center text-[#f8dfd7] text-[56px] py-2 ">WHY US</div>
        <div className=" rounded-2xl m-4 p-2 font-semibold text-center">"Our trainers have extensive training and technology expertise across different domains. Our courses are catered for everyone from students, to adult learners, from technology as well as other backgrounds and are offered face-to-face, as we believe this provides a better learning experience for participants "</div>
        <div>
            <img src={"https://storage.googleapis.com/betsite-imagedata/elearning/mission-bg.webp"}/>
        </div>
    </div>
}

export default MissionStatement