import React, { useState } from "react";
import { IoMenu, IoClose } from "react-icons/io5";
import { IconContext } from "react-icons";
import {useNavigate} from "react-router-dom";

const NavBar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const onCourseClick = (x) => {
        const targetElement = document.getElementById(x);
        if (targetElement) {
            targetElement.scrollIntoView({
                alignToTop: "true",
                behavior: "smooth",
            });

        }
    }

    const navigate = useNavigate()
    return (
        <div className="w-full bg-[#000000] py-4 flex flex-col">
            {/* Desktop Menu (Always visible on large screens) */}
            <div className="justify-between w-full items-center hidden lg:flex">
                <div className="h-20">
                    <img className="h-16 m-4 ml-16" src="https://storage.googleapis.com/betsite-imagedata/elearning/2.png" />
                </div>
                <div className="flex mx-8 items-center text-lg font-bold text-white mr-20 cursor-pointer">
                    <div className="m-4" onClick={()=>{onCourseClick('why-us')}}>Why US</div>
                    <div className="m-4" onClick={() => {onCourseClick('coursesSection')}}>Courses</div>
                    <div className="m-4" onClick={() => {onCourseClick('team')}}>Team</div>
                    <div className="m-4" onClick={() => {onCourseClick('contact')}}>Contact Us</div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className="flex justify-center items-center lg:hidden">
                <div>
                    <img className="h-8 m-4 ml-8" src="https://storage.googleapis.com/betsite-imagedata/elearning/2.png" />
                </div>

            </div>


        </div>
    );
};

export default NavBar;
