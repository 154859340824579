import React from "react";
import {FaAngleDoubleDown} from "react-icons/fa";

const HomePageBanner = () => {

    const onCourseClick = () => {
        const targetElement = document.getElementById("coursesSection");
        if (targetElement) {
            targetElement.scrollIntoView({
                alignToTop: "true",
                behavior: "smooth",
            });

        }
    }
    return <div className="intro h-[450px]">
        <div className="intro-wrapper h-full pb-8 flex flex-col  items-center">
            <div className="intro-text text-center py-2 pt-8">Experience a Breakthrough</div>
            <div className=" text-[40px] font-[800] text-center pt-8 text-white px-4">
                Boost up your skills with a new way of thinking.
            </div>
            <div className=" text-[16px] font-[800] text-center pt-2 text-[#838485] px-4">
                Learn by doing it.
            </div>

            <button
                className="bg-orange-400 rounded-md p-2 px-6 my-8 text-white font-bold text-[12px] flex items-center" onClick={onCourseClick}>
                Explore Courses
                <div className="px-2"><FaAngleDoubleDown/></div>
            </button>
        </div>


    </div>
}

export default HomePageBanner