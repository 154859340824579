import React, { useState } from 'react';

const CourseItem = ({ setModalStatus, data }) => {
    const [showDescription, setShowDescription] = useState(true); // true shows Description, false shows Outcome

    return (
        <div className="h-2/3 flex flex-col items-center space-y-4 bg-white p-4 shadow-md rounded-lg">
            {/* Segmented Control */}
            <div className="border rounded-lg inline-flex h-12 p-1 bg-gray-200 " role="group">
                <button
                    className={`px-4 py-2 rounded-lg ${showDescription ? 'bg-white  text-gray-800' : 'text-gray-800'}`}
                    onClick={() => setShowDescription(true)}
                >
                    Description
                </button>
                <button
                    className={`px-4 py-2 rounded-lg ${!showDescription ? 'bg-white text-gray-800' : 'text-gray-800'}`}
                    onClick={() => setShowDescription(false)}
                >
                    Outcome
                </button>
            </div>
            {/* Content Display */}
            <div className="text-center p-4">
                {showDescription ? (
                    <p className="text-gray-700 text-sm">{data?.description}</p>
                ) : (
                    <p className="text-gray-700 text-sm">{data?.outcome}</p>
                )}
            </div>
        </div>
    );
};

export default CourseItem;
